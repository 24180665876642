import { useEffect, useState } from "react";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import './Invoices.css';
import Navigation from '../../components/Navigation/Navigation'
import InvoiceTable from "../../components/Table/InvoiceTable";
import invoiceService from "../../services/invoice.service";
import orderService from "../../services/order.service";

export const Invoices = () => {
    const [invoices, setInvoices] = useState(null)

    const getUserInvoices = async () => {
        let invoices = await invoiceService.getInvoices();
        let orders = await orderService.getOrders();
        let tempInv = invoices.data;

        for (let i = 0; i < tempInv.length; i++) {
            let orderDetails = orders.data.find((order) => order.id == tempInv[i].order_id);
            tempInv[i].orderDetails = orderDetails
        }
        setInvoices(tempInv)
    }
    useEffect(() => {
        getUserInvoices()
    }, [])

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Invoices</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Invoices</h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Invoices</Card.Title>
                                {invoices ? <InvoiceTable data={invoices} rowsPerPage={5} refreshFunction={getUserInvoices}></InvoiceTable> : <div className="loaderSpinner text-center mt-5"><Spinner animation="border" variant="primary" /><h6 className="text-center">Getting invoice details</h6></div>}


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

import { useEffect, useState } from 'react'
import './Ticket.css';
import Navigation from '../../components/Navigation/Navigation'
import { Form, Badge, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import apiService from '../../services/apiService';
import ticketService from '../../services/ticket.service';


const Ticket = () => {

    const [ticketDetails, setTicketDetails] = useState(null)
    const [ticketComments, setTicketComments] = useState(null)
    const [fileError, setFileError] = useState('');
    const [commentPosted, setcommentPosted] = useState(false);
    const [commentPostedError, setcommentPostedError] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [characterCountError, setCharacterCountError] = useState(false)
    const [formData, setFormData] = useState({
        content: '',
        id: ticketDetails ? ticketDetails.id : null,
        files: []
    });

    const getTicket = async (id) => {
        let ticketData = await ticketService.getTicket(id);

        if (ticketData) {
            if (ticketData.data.comments !== null) {

                let ticketCommentsSorted = ticketData.data.comments.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                setTicketComments(ticketCommentsSorted)
            }
        }

        setTicketDetails(ticketData.data)

    }

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const validFileTypes = ['image/jpeg', 'image/gif', 'image/png', 'image/webp', 'application/pdf'];

        const invalidFiles = files.filter(file => !validFileTypes.includes(file.type));
        if (invalidFiles.length > 0) {
            setCharacterCountError(false);
            setcommentPosted(false);
            setcommentPostedError(false);
            setFileError('Invalid file type. Only JPG, GIF, PNG, WEBP, and PDF files are allowed.');
            return;
        }

        setFileError('');
        setFormData({
            ...formData,
            files: e.target.files
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisableButton(true)
        setcommentPosted(false)
        setCharacterCountError(false)

        if (formData.comment.length < 10) {
            setCharacterCountError(true);
            return;
        }

        const commentData = {
            id: ticketDetails.id,
            content: formData.comment,
            type: 'user',
            files: []
        };

        for (const file of formData.files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                commentData.files.push({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    data: reader.result
                });

                if (commentData.files.length === formData.files.length) {
                    sendTicketComment(commentData);
                }
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
        }

        if (formData.files.length === 0) {
            sendTicketComment(commentData);
        }
    };

    const sendTicketComment = async (commentData) => {
        try {

            let comment = await ticketService.postTicketComment(commentData);
            console.log(comment);
            if (comment) {
                setcommentPosted(true)
                setTimeout(() => {
                    getTicket(window.location.href.split("/tickets").pop().replace('/', ''))
                }, 2000);
            }
            setDisableButton(false)
        } catch (error) {
            setDisableButton(false);
            console.error('Error:', error);
            alert('Error submitting the ticket.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    function changeDate(dbDate) {
        let date = new Date(dbDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes()

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return year + '-' + month + '-' + dt + ' ' + hour + ":" + minutes;
    }

    const ticketPrio = (prio) => {
        let bgColor;

        switch (prio) {
            case "low":
                bgColor = "success"
                break;

            case "medium":
                bgColor = "warning"
                break;

            case "high":
                bgColor = "danger"
                break;

            default:
                bgColor = "danger"

        }

        return bgColor;
    }

    const ticketStatus = (status) => {
        let bgColor;

        switch (status) {
            case "open":
                bgColor = "success"
                break;

            case "new":
                bgColor = "info"
                break;


            case "closed":
                bgColor = "danger"
                break;

            default:
                bgColor = "danger"

        }

        return bgColor;
    }

    useEffect(() => {
        getTicket(window.location.href.split("/tickets").pop().replace('/', ''))
    }, [])

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Ticket</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / <a className="breadcrumb" href="/tickets">Tickets</a> / {ticketDetails ? ticketDetails.id : '...'}</h6>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="p-3 card">
                            <a href="/tickets"><FontAwesomeIcon icon={faArrowLeft} size={'xl'} /></a>
                            <h6 className="mt-2 color-centhost">Ticket {ticketDetails ? ticketDetails.id + ' - ' + ticketDetails.subject : '....'}</h6>


                            <div className="row mt-1">
                                <div className="col-6 col-md-3">
                                    <p className="fw-bold m-0">Priority</p>
                                    {ticketDetails && <Badge className="p-2" bg={ticketPrio(ticketDetails.priority)}>{ticketDetails.priority.toUpperCase(0)}</Badge>}
                                </div>
                                <div className="col-6 col-md-3">
                                    <p className="fw-bold  m-0">Status</p>
                                    {ticketDetails && <Badge className="p-2" bg={ticketStatus(ticketDetails.status)}>{ticketDetails.status.toUpperCase(0)}</Badge>}

                                </div>
                                <div className="col-6 col-md-3">
                                    <p className="fw-bold  m-0">Department</p>
                                    <p>{ticketDetails && ticketDetails.department}</p>
                                </div>
                                <div className="col-6 col-md-3">
                                    <p className="fw-bold  m-0">Created At</p>
                                    <p>{ticketDetails && changeDate(ticketDetails.created_at)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <p className="fw-bold  m-0">Last message</p>
                                    <p>{ticketDetails && changeDate(ticketDetails.updated_at)}</p>
                                </div>
                                <div className="col-6 col-md-3">
                                    {ticketDetails && ticketDetails.status == 'closed' && (
                                        <>
                                            <p className="fw-bold  m-0">Closed at</p>
                                            <p>{changeDate(ticketDetails.updated_at)}</p>
                                        </>
                                    )}
                                </div>
                                <div className="col-12 col-md-3">
                                    <p className="fw-bold  m-0">Created By</p>
                                    <p>{localStorage.getItem('email')}</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    {ticketDetails && ticketDetails.order_id !== null && (
                                        <>
                                            <p className="fw-bold  m-0">Connected Order #</p>
                                            <p>{ticketDetails.order_id}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-12">
                        <div className="p-3 card">
                            {ticketDetails && ticketDetails.status !== 'closed' && (
                                <div className="ticketPlaceComment m-2">
                                    <Form onSubmit={(e) => handleSubmit(e)}>
                                        <Form.Group className="mb-3">
                                            {commentPostedError && <Alert dismissible variant={'danger'} ><span>Error: </span> Something went wrong while posting your comment.</Alert>}
                                            {fileError && <Alert dismissible variant={'danger'} ><span>Error: </span> {fileError}</Alert>}
                                            {characterCountError && <Alert dismissible variant={'danger'} ><span>Error: </span> Please use more words to describe your problem.</Alert>}
                                            {commentPosted && <Alert dismissible variant={'success'}><span>Message: </span> Thank you, your comment has been posted. A staff member will look into your ticket as soon as possible.</Alert>}
                                            <div className="mb-3">
                                                <label htmlFor="comment" className="form-label">Comment</label>
                                                <textarea
                                                    className="form-control"
                                                    id="comment"
                                                    name="comment"
                                                    value={formData.comment}
                                                    onChange={handleChange}
                                                    rows="4"
                                                    placeholder="Write your comment"
                                                    required
                                                ></textarea>
                                            </div>
                                        </Form.Group>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/* <Form.Group className="mb-3">
                                                    <Form.Label>Files (jpg,png,gif or pdf)</Form.Label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        id="fileUpload"
                                                        name="files"
                                                        multiple
                                                        onChange={handleFileChange}
                                                    />
                                                </Form.Group> */}
                                            </div>
                                        </div>
                                        <button className="btn btn-centhost" type="submit">Post</button>
                                    </Form>
                                </div>
                            )}
                            {ticketComments ? ticketComments.map((ticketComment, key) => {
                                return (
                                    <div key={ticketComment.id} className="ticketMessage">
                                        <div className={ticketComment.type == 'staff' || ticketComment.type == 'auto' ? 'staffMessage row  p-3' : 'customerMessage row p-3'} key={key} >
                                            <div className={"col-md-2"}>
                                                <img alt='avatar' src={localStorage.getItem('avatar')} className="img-fluid img-circled" />
                                                <p>{ticketComment.type === 'staff' || ticketComment.type == 'auto' ? 'Centnodes Support Team' : localStorage.getItem('email')}</p>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="ticketContent">
                                                    {ticketComment.content}
                                                    {/* Parse HTML  */}
                                                </div>
                                            </div>
                                            <div className="col-md-2 align-self-end">
                                                <p className='mt-2 fw-bold'>{changeDate(ticketComment.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <p>No comments.</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Ticket
import { useState, useEffect, } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import './Login.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet-async";
import authService from "../../services/auth.service";
import logo_dark from '../../images/logo_white.png';

function TwoFactor() {

    const [inputCode, setInputCode] = useState(null)
    const [loginError, setLoginError] = useState(null)
    const [loginSucces, setLoginSuccess] = useState(false)


    async function checkTwoFactorCode(event) {
        event.preventDefault()
        let isCodeValid = await authService.checkTwoFactor(localStorage.getItem('id'), inputCode);

        if (isCodeValid.email) {
            setLoginError(null)

            localStorage.setItem('email', isCodeValid.email)
            localStorage.setItem('avatar', isCodeValid.avatar)
            localStorage.setItem('token', isCodeValid.token)
            localStorage.setItem('xad', true)

            window.location.href = '/dashboard'
        } else {
            setLoginError('Error: This is the wrong code. Try again')
        }
    }

    useEffect(() => {

    })

    return (
        <div>
            <Helmet>
                <title>Centnodes | Two Factor Authentication</title>
                <meta
                    name="description"
                    content="Login to your account."
                />;
            </Helmet>
            <div className="hero-short p-1 ">
                <div className="container mt-5 justify-content-center d-flex">
                    <img src={logo_dark} className="loginLogo img-fluid" alt="company logo dark" />
                </div>
            </div>
            <Container>
                <div className="row d-flex justify-content-center loginRow pb-4">
                    <div className="col-md-6">
                        <div className="loginBox bg-light border p-2 pb-3 text-center">

                            <h4 className=" h4 color-primary mt-3">Login to your account</h4>
                            <p className="color-gray">Enter your 6 digit code received via your email.</p>
                            {loginError ? (
                                <Alert className="p-2" variant={'danger'}><span>Error: </span> {loginError}</Alert>

                            ) : null}
                            {loginSucces ? (
                                <Alert variant={'success'}><span>Success! Login you in. </span></Alert>

                            ) : null}

                            <Form
                                onSubmit={checkTwoFactorCode}>
                                <Form.Group className="p-4 text-start d-grid gap-2">
                                    <Form.Label className="mt-2">6 Digit code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="inputCode"
                                        onChange={(e) => setInputCode(e.target.value)}

                                    />

                                </Form.Group>
                                <div className="d-grid gap-">
                                    <Button type="submit" id="loginSubmit" className="d-grid btn btn-centhost">Submit
                                    </Button>
                                </div>
                            </Form>
                            <p className="m-0"><a target="_blank" href="https://centnodes.com/help"><FontAwesomeIcon className="mt-5 " icon={faQuestionCircle} /> Trouble logging in?</a></p>
                            <p className="m-0"><a href="/"><FontAwesomeIcon className="mt-5 " icon={faArrowAltCircleLeft} /> Back to homepage</a></p>
                        </div>
                    </div>
                </div>
            </Container >
        </div>
    )
}
export default TwoFactor;
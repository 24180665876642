import { useEffect, useState } from 'react'
import './Settings';
import Navigation from '../../components/Navigation/Navigation'
import { Row, Col, Card, Form, Button } from 'react-bootstrap'
import userService from '../../services/user.service';
import { toast } from 'react-toastify';


const Settings = () => {

  const [user, setUser] = useState(null)
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [userZip, userSetZip] = useState(null);
  const [userStreet, userSetStreet] = useState(null);
  const [userStreetNumber, userSetStreetNumber] = useState(null);
  const [userState, userSetState] = useState(null);
  const [userCity, userSetCity] = useState(null);
  const [userCountry, userSetCountry] = useState(null);
  const [userFirstName, userSetFirstName] = useState(null);
  const [userLastName, userSetLastName] = useState(null);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const getUser = async () => {
    let user = await userService.getUser();

    userSetZip(user.data.zip)
    userSetStreet(user.data.street)
    userSetStreetNumber(user.data.street_number)
    userSetState(user.data.state)
    userSetCity(user.data.city)
    userSetCountry(user.data.country)
    userSetFirstName(user.data.firstname)
    userSetLastName(user.data.lastname)

    setTwoFactorEnabled(user.data['2fa_enabled'])

    // todo add mailing settings.

    setUser(user.data)
  }

  const enabledTwoFactor = async () => {
    return false;

  }

  const saveSettings = async (e) => {
    e.preventDefault()
    setDisableSaveButton(true)
    let values = {
      firstname: userFirstName,
      lastname: userLastName,
      street: userStreet,
      street_number: userStreetNumber,
      city: userCity,
      country: userCountry,
      state: userState,
      zip: userZip
    }
    console.log(values)

    // update details;
    let updateDetails = await userService.updateUserDetails(values);

    if (updateDetails) {
      toast("Success: Your details have been updated.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      setDisableSaveButton(false)
    } else {
      return false;
    }

  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className="content">
      <Navigation />
      <div id="main" className="px-3 mt-5">
        <h4 className="mt-3 page-title">Settings</h4>
        <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Settings</h6>

        <div className='row mt-4'>
          <div className="col-md-8">
            <Card>
              <Card.Body>
                <div className="userAvatar">
                </div>
                <Card.Title>Billing Information</Card.Title>
                <Form onSubmit={saveSettings}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control required onChange={(event) => userSetFirstName(event.target.value)} value={userFirstName} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control required onChange={(event) => userSetLastName(event.target.value)} value={userLastName} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>Street</Form.Label>
                      <Form.Control required onChange={(event) => userSetStreet(event.target.value)} value={userStreet} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Number</Form.Label>
                      <Form.Control required onChange={(event) => userSetStreetNumber(event.target.value)} value={userStreetNumber} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Country</Form.Label>
                      <Form.Control disabled required onChange={(event) => userSetCountry(event.target.value)} value={userCountry} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control required onChange={(event) => userSetCity(event.target.value)} value={userCity} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>State</Form.Label>
                      <Form.Control required onChange={(event) => userSetState(event.target.value)} value={userState} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control required onChange={(event) => userSetZip(event.target.value)} value={userZip} />
                    </Form.Group>
                  </Row>

                  <Button disabled={disableSaveButton} className="btn btn-centhost p-2 mt-4 float-end" type='submit'>Save</Button>

                </Form>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <Card.Body>
                <Card.Title>Two Factor Authetication</Card.Title>
                <Form >
                  <Form.Check
                    checked={twoFactorEnabled}
                    onChange={enabledTwoFactor}
                    type="switch"
                    id="twoFactorswitch"
                  />
                </Form>
                {!twoFactorEnabled ?
                  (
                    <p>2FA Disabled</p>
                  ) :
                  (
                    <p>2FA Enabled</p>
                  )
                }

              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>Mailing settings</Card.Title>
                <Form >
                  <Form.Check
                    checked={user ? user['2fa_enabled'] ? true : false : false}
                    // onChange={enabledTwoFactor}
                    type="switch"
                    id="mailingSwitchNewLogin"
                  />
                </Form>
                <p>New login</p>

                <Form >
                  <Form.Check
                    checked={false}
                    // onChange={enabledTwoFactor}
                    type="switch"
                    id="mailingSwitchNewLogin"
                  />
                </Form>
                <p>Newsletter</p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Settings
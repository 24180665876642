import axios from "axios";
import authService from "./auth.service";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api';
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api';
            break;
        default:
            res = 'https://api.centnodes.com/api';
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);
const token = localStorage.getItem('token');
const config = { headers: { 'Authorization': 'Bearer ' + token } };

const getUser = async () => {
    try {
        const user = await axios.get(API_URL + '/user/', config);

        return user.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const updateUserDetails = async (data) => {
    try {
        const updateUser = await axios.post(API_URL + '/user/updatedetails', { data }, config);

        return updateUser.data.status;
    } catch (err) {
        return;
    }
}

const resetPassword = async (data) => {
    try {
        const response = await axios.post(API_URL + '/auth/password/reset', data);

        return response.data;
    } catch (err) {
        throw err;
    }
}

const requestPasswordReset = async (email) => {
    try {
        const response = await axios.post(API_URL + '/auth/password/request-reset', { email });

        return response.data;
    } catch (err) {
        throw err;
    }
}

export default { requestPasswordReset, getUser, updateUserDetails, resetPassword };

import { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import './GameServers.css';
import Navigation from '../../../components/Navigation/Navigation'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import GamingTable from "../../../components/Table/GamingTable";
import gamingService from "../../../services/gaming.service";

export const GameServers = () => {

    const [gamingPackages, setGamingPackages] = useState()

    const getGamingPackages = async () => {
        let gamingPackagesData = await gamingService.getGamingPackages();
        if (gamingPackagesData) {
            let gamingPackagesFiltered = gamingPackagesData.data.filter(gamepack => gamepack.status !== 'pending')
            setGamingPackages(gamingPackagesFiltered)
        }
    }



    useEffect(() => {
        getGamingPackages()
    }, [])


    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Game Servers</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Gaming</h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h6 className="d-flex justify-content-between">Gaming<div className="cardTitleButtons"><button onClick={() => window.location.href = '/products/gaming/deploy'} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button></div></h6>
                                {gamingPackages && <GamingTable data={gamingPackages} rowsPerPage={5} refreshFunction={getGamingPackages} pagination={true} />}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </div >

        </div >
    )
}

import { useEffect, useState } from "react";
import './GameServers.css';
import Navigation from '../../../components/Navigation/Navigation'
import gamingService from "../../../services/gaming.service";
import { faHardDrive, faMemory, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBox, faChartBar, faGlobe, faInfinity, faMailBulk } from "@fortawesome/free-solid-svg-icons";

export const GameServer = () => {

    const [gameServer, setGameServer] = useState()
    const [gameServerDetails, setGameServerDetails] = useState();


    const getGameServer = async (id) => {

        let gamingDetails = await gamingService.getGamingPackage(id);
        setGameServer(gamingDetails.data)

        let gameServerDetails = await gamingService.getGamingPackageDetails(id);
        setGameServerDetails(gameServerDetails.data.attributes)
    }
    const getServerCores = (cpu_value) => {
        let cpus = cpu_value.toString();
        return cpus.replaceAll('0', '');
    }

    useEffect(() => {
        getGameServer(window.location.href.split("/gaming").pop().replace('/', ''))
    }, [])



    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Gameserver details</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / <a className="breadcrumb" href="/products/gaming">Gaming </a> / {gameServer && gameServer.id && gameServer.label}</h6>

                <div className="row mt-3 text-center  make-equal ">
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faMemory} /> <p>{gameServerDetails && gameServerDetails.limits.memory + 'MB'}</p><span className="fw-bold"></span>RAM</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faMicrochip} /> <p>{gameServerDetails && (getServerCores(gameServerDetails.limits.cpu))}</p><span className="fw-bold"></span>Cores</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faHardDrive} /> <p>{gameServerDetails && gameServerDetails.limits.disk + 'MB'}</p><span className="fw-bold"></span>Disk</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faBox} /> <p>{gameServer && gameServer.status}</p><span className="fw-bold"></span>Status</h5>
                        </div>
                    </div>
                </div>

                <Row>

                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Overview</Card.Title>
                                <a href="/products/gaming"><FontAwesomeIcon icon={faArrowLeft} size={'xl'} /></a>

                                {gameServerDetails ?

                                    <div className="row">
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul className="list-unstyled">
                                                    <li className="list-item my-1"><span className="fw-bold">Label: </span>{gameServer.label ? gameServer.label.replace('centnodes-', '') : 'N/a'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul className="list-unstyled">
                                                    <li className="list-item my-1"><span className="fw-bold">IP: </span>{gameServer.ip ? gameServer.ip : 'N/a'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul class="list-group">
                                                    <li class="list-group-item btn btn-centhost btn-rounded"><a target="_blank" href={'https://games.centnodes.com'}>Control Panel</a></li>
                                                    <li class="list-group-item btn btn-centhost btn-rounded mt-1"><a target="_blank" href={'https://pma.centnodes.com/hi'}>phpMyAdmin</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="text-center"><Spinner className="text-center" animation="border" variant="primary" /><h6 className="text-center">Loading...</h6></div>}
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>

            </div>
        </div >
    )
}

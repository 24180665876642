import { useEffect, useState } from "react";
import './DeployCloud.css';
import Navigation from '../../../components/Navigation/Navigation';
import nlFlag from '../../../images/flags/flag_nl.png';

const DeployCloud = () => {
    const mockOSList = [
        { id: 1, name: 'Debian 12', family: 'debian' },
        { id: 2, name: 'Windows Server 2019', family: 'windows' },
    ];

    const mockLocations = {
        amsterdam: {
            name: "Amsterdam",
            country: "Netherlands",
            flag: nlFlag
        }
    };

    const mockPlans = [
        { id: 1, name: '2GB RAM,1 vCPU,32GB SSD', price: '10.00', description: '2GB RAM,1 vCPU,32GB SSD' },
        { id: 2, name: '4GB RAM,2 vCPU,48GB SSD', price: '20.00', description: '4GB RAM,2 vCPU,48GB SSD' },
        { id: 3, name: '4GB RAM,4 vCPU,48GB SSD', price: '25.00', description: '4GB RAM,4 vCPU,48GB SSD' },
        { id: 4, name: '8GB RAM,4 vCPU,64GB SSD', price: '40.00', description: '8GB RAM,4 vCPU,64GB SSD' },
        { id: 5, name: '8GB RAM,8 vCPU,100GB SSD', price: '60.00', description: '8GB RAM,8 vCPU,100GB SSD' },
        { id: 6, name: '12GB RAM,8 vCPU,100GB SSD', price: '80.00', description: '12GB RAM,8 vCPU,100GB SSD' },
        { id: 7, name: '12GB RAM,10 vCPU,200GB SSD', price: '100.00', description: '12GB RAM,10 vCPU,200GB SSD' },
        { id: 8, name: '16GB RAM,10 vCPU,200GB SSD', price: '120.00', description: '16GB RAM,10 vCPU,200GB SSD' },
        { id: 9, name: '16GB RAM,12 vCPU,250GB SSD', price: '140.00', description: '16GB RAM,12 vCPU,250GB SSD' },
    ];

    const [data, setData] = useState({
        osList: mockOSList,
        plans: mockPlans,
        availablePlans: [],
        selectedPlanId: null,
        serverOS: null,
        serverLocation: null,
        selectedOSName: '',
        featureBackup: false,
        featureDDOS: false,
        featureSupport: false,
        serverHostname: '',
        serverLabel: '',
        backupPrice: 0,
        ddosPrice: 0,
        supportPrice: 0,
        checkoutDisabled: true,
        checkoutError: false,
        hostnameError: '',
        labelError: '',
    });

    const hostnameRegex = /^[a-zA-Z0-9-]{1,63}$/;

    const calculateAdditionalCosts = (planPrice) => {
        setData((prevData) => ({
            ...prevData,
            backupPrice: (planPrice * 0.20).toFixed(2),
            ddosPrice: (planPrice * 0.10).toFixed(2),
            supportPrice: planPrice >= 15 ? (planPrice * 0.25).toFixed(2) : 0,
        }));
    };

    const updateOS = (osId) => {
        const selectedOS = data.osList.find(os => os.id === osId);
        setData((prevData) => ({
            ...prevData,
            serverOS: osId,
            selectedOSName: selectedOS ? selectedOS.name : '',
            availablePlans: [],
            selectedPlanId: null,
            serverLocation: null,
            checkoutDisabled: true,
        }));
    };

    const updateServerLocation = (location) => {
        setData((prevData) => ({
            ...prevData,
            serverLocation: location,
            availablePlans: mockPlans,
            selectedPlanId: null,
            checkoutDisabled: true,
        }));
    };

    const createOrder = () => {
        const { serverOS, serverLabel, serverHostname, serverLocation, selectedPlanId, featureDDOS, featureBackup, featureSupport } = data;

        if (!serverOS || !serverLabel || !serverHostname) {
            setData((prevData) => ({ ...prevData, checkoutError: true }));
            return;
        }

        if (serverHostname.length < 4 || serverLabel.length < 4) {
            setData((prevData) => ({ ...prevData, checkoutError: true }));
            return;
        }

        setData((prevData) => ({ ...prevData, checkoutError: false }));

        const selectedPlan = mockPlans.find(plan => plan.id === selectedPlanId);

        const serverDetails = [{
            osId: serverOS,
            serverLabel: serverLabel,
            hostname: serverHostname,
            location: serverLocation,
            productType: 'cloud',
            planId: selectedPlanId,
            features: {
                ddos: featureDDOS,
                backup: featureBackup,
                support: featureSupport
            },
            specs: {
                ram: selectedPlan.name.split(',')[0],
                cpu: selectedPlan.name.split(',')[1],
                disk: selectedPlan.name.split(',')[2]
            }
        }];

        localStorage.setItem('temp_order_details', JSON.stringify(serverDetails));
        window.location.href = '/checkout';
    };

    const updateSelectedPlan = (planId) => {
        const selectedPlan = mockPlans.find(plan => plan.id === planId);
        if (selectedPlan) {
            setData((prevData) => ({
                ...prevData,
                selectedPlanId: planId,
                checkoutDisabled: !(data.serverHostname.length >= 4 && data.serverLabel.length >= 4),
            }));
            calculateAdditionalCosts(parseFloat(selectedPlan.price));
        } else {
            setData((prevData) => ({ ...prevData, checkoutDisabled: true }));
        }
    };

    const handleHostnameChange = (event) => {
        const value = event.target.value;
        setData((prevData) => ({
            ...prevData,
            serverHostname: value,
            hostnameError: !hostnameRegex.test(value) ? 'Invalid hostname' : '',
            checkoutDisabled: !(value.length >= 4 && data.serverLabel.length >= 4),
        }));
    };

    const handleLabelChange = (event) => {
        const value = event.target.value;
        setData((prevData) => ({
            ...prevData,
            serverLabel: value,
            labelError: value.length < 4 ? 'Label must be at least 4 characters' : '',
            checkoutDisabled: !(value.length >= 4 && data.serverHostname.length >= 4),
        }));
    };

    useEffect(() => {
        localStorage.removeItem('temp_order_details');
    }, []);

    const groupedOS = data.osList.reduce((acc, osItem) => {
        if (!acc[osItem.family]) {
            acc[osItem.family] = [];
        }
        acc[osItem.family].push(osItem);
        return acc;
    }, {});

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Deploy a server</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/clouds"> Servers</a> / Deploy a Server
                </h6>
                <div className="row mt-4">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <h3 className="color-primary mt-2 text-center">Server Operating System</h3>
                                    {Object.keys(groupedOS).length ? (
                                        <div className="row">
                                            {Object.keys(groupedOS).map(family => (
                                                <div key={family} className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3">
                                                    <div className={`osFamilyCard ${data.serverOS && groupedOS[family].some(osItem => osItem.id === data.serverOS) ? 'selected' : ''}`}>
                                                        <div className="osFamilyItem">
                                                            <img src={require(`../../../images/os/icon-${family}.png`)} className="img-fluid osFamilyImage" alt={family} />
                                                            <div className="osFamilyDetails">
                                                                <h5>{family}</h5>
                                                                <select
                                                                    value={data.serverOS}
                                                                    onChange={(e) => updateOS(e.target.value)}
                                                                    className="mt-2 form-select"
                                                                >
                                                                    <option value="">Select {family}</option>
                                                                    {groupedOS[family].map(osItem => (
                                                                        <option key={osItem.id} value={osItem.id}>{osItem.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center"><div className="spinner-border text-primary" role="status"></div></div>
                                    )}
                                </div>

                                {data.serverOS && (
                                    <ServerLocationSelector updateServerLocation={updateServerLocation} selectedLocation={data.serverLocation} locations={mockLocations} />
                                )}

                                {data.serverLocation && (
                                    <div className="row mt-5 justify-content-center">
                                        <h3 className="color-primary text-center">Select package</h3>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">RAM</th>
                                                    <th scope="col">CPU</th>
                                                    <th scope="col">Disk Space</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.availablePlans.map(plan => (
                                                    <tr key={plan.id} onClick={() => updateSelectedPlan(plan.id)} className={data.selectedPlanId === plan.id ? 'table-active' : ''}>
                                                        <td>{plan.name.split(',')[0]}</td>
                                                        <td>{plan.name.split(',')[1]}</td>
                                                        <td>{plan.name.split(',')[2]}</td>
                                                        <td>€{plan.price}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {data.selectedPlanId && (
                                    <div className="mt-5">
                                        <h3 className="color-primary text-center">Additional Features</h3>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="featureDDOS" onChange={() => setData(prevData => ({ ...prevData, featureDDOS: !prevData.featureDDOS }))} checked={data.featureDDOS} />
                                                    <label className="form-check-label" htmlFor="featureDDOS">
                                                        Enable DDOS Protection + €{data.ddosPrice} (10% of plan price)
                                                    </label>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="featureBackup" onChange={() => setData(prevData => ({ ...prevData, featureBackup: !prevData.featureBackup }))} checked={data.featureBackup} />
                                                    <label className="form-check-label" htmlFor="featureBackup">
                                                        Enable Backups + €{data.backupPrice} (20% of plan price)
                                                    </label>
                                                </div>
                                            </li>
                                            {parseFloat(data.plans.find(x => x.id === data.selectedPlanId)?.price) >= 15 && (
                                                <li className="list-group-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="featureSupport" onChange={() => setData(prevData => ({ ...prevData, featureSupport: !prevData.featureSupport }))} checked={data.featureSupport} />
                                                        <label className="form-check-label" htmlFor="featureSupport">
                                                            Support Premium + €{data.supportPrice} (25% of plan price)
                                                        </label>
                                                    </div>
                                                    <ul>
                                                        <li>Fast response time</li>
                                                        <li>Dedicated communications channel</li>
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}

                                <HostnameLabelForm
                                    hostname={data.serverHostname}
                                    label={data.serverLabel}
                                    handleHostnameChange={handleHostnameChange}
                                    handleLabelChange={handleLabelChange}
                                    hostnameError={data.hostnameError}
                                    labelError={data.labelError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="color-primary text-center mt-2">Your configuration</h3>
                                {data.checkoutError && <div className="alert alert-danger" role="alert"><span className="fw-bold">Error:</span> Fill in required fields correctly.</div>}
                                {data.selectedPlanId && <p className="m-0"><span className="fw-bold">Package: </span> {data.availablePlans.find(x => x.id === data.selectedPlanId)?.name}</p>}
                                {data.serverOS && <p className="m-0"><span className="fw-bold">Operating System: </span> {data.selectedOSName}</p>}
                                {data.serverLocation && <p className="m-0"><span className="fw-bold">Region: </span> {mockLocations[data.serverLocation].name}, {mockLocations[data.serverLocation].country}</p>}
                                {data.featureBackup && <p className="m-0"><span className="fw-bold">Feature: </span> Backups + €{data.backupPrice}</p>}
                                {data.featureDDOS && <p className="m-0"><span className="fw-bold">Feature: </span> DDOS protection + €{data.ddosPrice}</p>}
                                {data.featureSupport && <p className="m-0"><span className="fw-bold">Feature: </span> Support Premium + €{data.supportPrice}</p>}
                                {data.selectedPlanId && <p className="m-0"><span className="fw-bold">Price: </span> €{data.availablePlans.find(x => x.id === data.selectedPlanId)?.price}</p>}
                                <button disabled={data.checkoutDisabled} id="createOrderButton" className="btn btn-primary w-100 mt-2" onClick={createOrder}>Go to Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ServerLocationSelector = ({ updateServerLocation, selectedLocation, locations }) => (
    <div className="row mt-5 gap-1 justify-content-center">
        <h3 className="color-primary text-center">Server Location</h3>
        {Object.keys(locations).map(location => (
            <div key={location} data-id={location} className={`col-4 col-md-3 osOption serverLocationItem ${selectedLocation === location ? 'osOptionSelected' : ''}`} onClick={() => updateServerLocation(location)}>
                <div className="row">
                    <div className="col-md-6">
                        <img src={locations[location].flag} className="flagLocation img-fluid" />
                    </div>
                    <div className="col-12 col-md-6">
                        <p className="fw-bold">{locations[location].name}</p>
                        <p className="">{locations[location].country}</p>
                    </div>
                </div>
            </div>
        ))}
    </div>
);

const HostnameLabelForm = ({ hostname, label, handleHostnameChange, handleLabelChange, hostnameError, labelError }) => (
    <div className="row mt-5">
        <h3 className="color-primary text-center">Hostname & Label</h3>
        <div className="pb2">
            <div className="row">
                <div className="col-md-6">
                    <input
                        className="form-control"
                        type="text"
                        value={hostname}
                        onChange={handleHostnameChange}
                        placeholder="Server Hostname"
                    />
                    {hostnameError && <div className="invalid-feedback d-block">{hostnameError}</div>}
                </div>
                <div className="col-md-6">
                    <input
                        className="form-control"
                        type="text"
                        value={label}
                        onChange={handleLabelChange}
                        placeholder="Server Label"
                    />
                    {labelError && <div className="invalid-feedback d-block">{labelError}</div>}
                </div>
            </div>
        </div>
    </div>
);

export default DeployCloud;

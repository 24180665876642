import axios from "axios";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com:2083'
            break;
        default:
            res = 'https://api.centnodes.com:2083'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);

function delete_cookie(name, path, domain) {

    document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";

}


const logout = () => {
    localStorage.clear()
    delete_cookie("CENTNODES-AUTH-SECRET", '/', window.location.href.includes("localhost") ? 'localhost' : '.centnodes.com')
    window.location.assign("/login");
}




const getUser = async (id) => {
    if (!id) {
        return false;
    }
    try {
        const users = await axios.get(API_URL + '/users/' + id, { withCredentials: true })

        return users.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const getServers = async () => {
    try {
        const servers = await axios.get(API_URL + '/servers', { withCredentials: true })

        return servers.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getServer = async (id) => {
    if (!id) {
        return false;
    }
    try {
        const server = await axios.get(API_URL + '/servers/' + id, { withCredentials: true })

        return server.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getInstanceDetails = async (externalId) => {
    if (!externalId) {
        return false;
    }
    try {
        const instanceDetails = await axios.get(API_URL + '/instance/' + externalId, { withCredentials: true })

        return instanceDetails.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getHostingPackages = async () => {
    try {
        const hostingPackages = await axios.get(API_URL + '/hosting', { withCredentials: true })

        return hostingPackages.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getOperationSystems = async () => {
    try {
        const osList = await axios.get(API_URL + '/oslist', { withCredentials: true })

        return osList.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getApplications = async () => {
    try {
        const applications = await axios.get(API_URL + '/applications', { withCredentials: true })

        return applications.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getHostingPackage = async (id) => {
    try {
        const hostingPackage = await axios.get(API_URL + '/hosting/' + id, { withCredentials: true })

        return hostingPackage.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getInstanceBackupData = async (id) => {
    try {
        const backupData = await axios.get(API_URL + '/instance/' + id + '/backups', { withCredentials: true })

        return backupData.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getFirewallRules = async (id) => {
    try {
        const backupData = await axios.get(API_URL + '/instance/firewall/' + id + '/firewall-rules', { withCredentials: true })

        return backupData.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const updateBackupSchedule = async (schedule, id) => {
    try {
        const updateSchedule = await axios.post(API_URL + '/instance/' + id + '/backup-schedule', { type: schedule.type, dow: schedule.dow, dom: schedule.dom, hour: schedule.hour }, { withCredentials: true })

        if (updateSchedule) {
            return true
        }
        return false;
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}
const startInstance = async (id) => {

    try {
        const startInstance = await axios.get(API_URL + '/instance/' + id + '/start/', { withCredentials: true })

        return startInstance
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const shutdownInstance = async (id) => {

    try {
        const stopInstance = await axios.get(API_URL + '/instance/' + id + '/stop/', { withCredentials: true })

        return stopInstance
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const rebootInstance = async (id) => {

    try {
        const rebootInstance = await axios.get(API_URL + '/instance/' + id + '/reboot/', { withCredentials: true })

        return rebootInstance
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
        if (err.response.status === 404) {

            return null;
        }
    }
}

const addFirewallRule = async (firewallRule) => {
    try {
        const postComment = await axios.post(API_URL + '/instance/firewall/' + firewallRule.group_id + '/firewall-rules/add', { firewallRule }, { withCredentials: true })

        return postComment.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const removeFirewallRule = async (firewallGroupId, ruleId) => {
    try {
        const postComment = await axios.post(API_URL + '/instance/firewall/' + firewallGroupId + '/firewall-rules/remove/' + ruleId, { firewallGroupId, ruleId }, { withCredentials: true })

        return postComment.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const getInvoices = async () => {
    try {
        const invoices = await axios.get(API_URL + '/invoices', { withCredentials: true })

        return invoices.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getInvoice = async (id) => {
    try {
        const invoice = await axios.get(API_URL + '/invoice' + id, { withCredentials: true })

        return invoice.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getTickets = async () => {
    try {
        const tickets = await axios.get(API_URL + '/tickets', { withCredentials: true })

        return tickets.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getOrders = async () => {
    try {
        const orders = await axios.get(API_URL + '/orders', { withCredentials: true })

        return orders.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getTicket = async (id) => {
    try {
        const ticket = await axios.get(API_URL + '/tickets/' + id, { withCredentials: true })

        return ticket.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getKnowledgebaseItems = async () => {
    try {
        const items = await axios.get(API_URL + '/knowledgebase', { withCredentials: true })

        return items.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}

const getKnowledgebaseItem = async (id) => {
    try {
        const item = await axios.get(API_URL + '/knowledgebase/' + id, { withCredentials: true })

        return item.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}
const getKnowledgebaseItemBySlug = async (slug) => {
    try {
        const item = await axios.get(API_URL + '/knowledgebase/slug/' + slug, { withCredentials: true })

        return item.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }
}


const getProducts = async () => {
    try {
        const prodcuts = await axios.get(API_URL + '/products/', { withCredentials: true })

        return prodcuts.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }

}
const getProductsByType = async (type) => {
    try {
        const products = await axios.get(API_URL + '/products/type/' + type, { withCredentials: true })

        return products.data
    } catch (err) {
        if (err.response.status === 403) {
            logout()
            return;
        }
    }

}
const createHostingOrder = async (data) => {

    try {
        const createOrder = await axios.post(API_URL + '/hosting/createorder', data, { withCredentials: true })

        return createOrder.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const createServerOrder = async (data) => {


    try {
        const createOrder = await axios.post(API_URL + '/servers/createorder', data, { withCredentials: true })

        return createOrder.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const getOrderPaymentStatus = async (orderId) => {

    try {
        const status = await axios.get(API_URL + '/orders/' + orderId + '/payment/status', { withCredentials: true })

        return status.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const getGamingProducts = async () => {
    try {
        const products = await axios.get(API_URL + '/products/type/gaming', { withCredentials: true })

        return products.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}
const CreatingGamingOrder = async (data) => {


    try {
        const createOrder = await axios.post(API_URL + '/gaming/createorder', data, { withCredentials: true })

        return createOrder.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const updateUserDetails = async (data) => {

    try {
        const saveUserDetails = await axios.patch(API_URL + '/users/', data, { withCredentials: true })

        return saveUserDetails.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const enableTwoFactor = async (id) => {
    try {
        const enable2fa = await axios.get(API_URL + '/users/' + id + '/enabletwofactor', { withCredentials: true })

        return enable2fa.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const disableTwoFactor = async (id) => {
    try {
        const disable2fa = await axios.get(API_URL + '/users/' + id + '/disabletwofactor', { withCredentials: true })

        return disable2fa.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const newTwoFactorCode = async (id) => {
    try {
        const newCode = await axios.get(API_URL + '/users/' + id + '/newtwofactorcode', { withCredentials: true })

        return newCode.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}


const getTwoFactorCode = async (id) => {
    try {
        const twoFactorCode = await axios.get(API_URL + '/users/' + id + '/twofactorcode', { withCredentials: true })

        return twoFactorCode.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const checkTwoFactor = async (id, code) => {
    try {
        const twoFactorCode = await axios.post(API_URL + '/users/' + id + '/verify2fa', { code: code }, { withCredentials: true })

        return twoFactorCode.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}
const getBlogItemBySlug = async (slug) => {
    try {
        const blogItemBySlug = await axios.get(API_URL + '/blog/slug/' + slug, { withCredentials: true })

        return blogItemBySlug.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

const getBlogItems = async () => {
    try {
        const blogItems = await axios.get(API_URL + '/blog', { withCredentials: true })

        return blogItems.data
    } catch (err) {
        if (err.response.status === 401) {
            logout()
            return;
        }
    }
}

export default {getBlogItems, getBlogItemBySlug, updateUserDetails, checkTwoFactor, enableTwoFactor, disableTwoFactor, getTwoFactorCode, newTwoFactorCode, CreatingGamingOrder, getGamingProducts, getOrderPaymentStatus, createHostingOrder, createServerOrder, getApplications, getProducts, getProductsByType, getOrders, getKnowledgebaseItem, getKnowledgebaseItemBySlug, getKnowledgebaseItems, getTickets, getTicket, getInvoice, getInvoices, addFirewallRule, removeFirewallRule, rebootInstance, startInstance, shutdownInstance, getFirewallRules, updateBackupSchedule, getInstanceBackupData, getHostingPackage, getOperationSystems, getHostingPackages, getUser, getServer, getServers, getInstanceDetails };

import './Overview.css';
import { useState, useEffect } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CloudOverview = ({ cloudDetails }) => {
    const [cloudData, setCloudData] = useState([]);

    useEffect(() => {
        setCloudData(cloudDetails);

    }, []);

   

    // if (!cloudData) {
    //     return (
    //         <div className="text-center p-3">
    //             <Spinner className="text-center" animation="border" variant="primary" />
    //             <h6 className="text-center">Getting cloud details</h6>
    //         </div>
    //     );
    // }

    return (
        <div className="cloud-overview">
            <div className="card-body">
                <div className="card-title">Overview</div>
                <a href="/products/clouds"><FontAwesomeIcon icon={faArrowLeft} size={'xl'} /></a>
                
                <div className="row mt-3 gx-1">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="p-3">
                            <ul className="list-unstyled">
                                <li className="list-item">
                                    <span className="fw-bold">Status: </span>
                                    {cloudData.status === 'stopped' && <Badge bg="danger">Stopped</Badge>}
                                    {cloudData.status === 'cancelled' && <Badge bg="danger">Cancelled</Badge>}
                                    {cloudData.status === 'pending' && <Badge bg="warning">Pending</Badge>}
                                    {cloudData.status === 'installing' && <Badge bg="info">Installing</Badge>}
                                    {cloudData.status === 'running' && <Badge bg="success">Running</Badge>}
                                </li>
                                <li className="list-item my-1"><span className="fw-bold">IP Address: </span>{cloudData.ip}</li>
                                {cloudData.v6_main_ip && (<li className="list-item my-1"><span className="fw-bold">IPv6 Address: </span>{cloudData.v6_main_ip}</li>)}
                                <li className="list-item my-1"><span className="fw-bold">Username: </span>root (Administrator for windows)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="p-3">
                            <ul className="list-unstyled">
                                <li className="list-item my-1"><span className="fw-bold">vCPU/s: </span>{cloudData.cpu} vCPUs</li>
                                <li className="list-item my-1"><span className="fw-bold">RAM: </span>{cloudData.ram} GB</li>
                                <li className="list-item my-1"><span className="fw-bold">Storage: </span>{cloudData.disk} GB</li>
                                {/* <li className="list-item my-1"><span className="fw-bold">Bandwidth: </span>0.00 GB<span>/{cloudData.allowed_bandwidth} GB</span></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="p-3">
                            <ul className="list-unstyled">
                                {/* <li className="list-item my-1"><span className="fw-bold">Label: </span>{cloudData.label.replace('centnodes-', '')}</li> */}
                                <li className="list-item my-1"><span className="fw-bold">OS: </span>{cloudData.os_id}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CloudOverview;

import { useEffect, useState } from "react";
import { Col, Row, Card, Badge } from "react-bootstrap";
import './Clouds.css';
import Navigation from '../../../components/Navigation/Navigation'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import CloudTable from "../../../components/Table/CloudTable";
import cloudService from "../../../services/cloud.service";

export const Clouds = () => {

    const [clouds, setClouds] = useState()

    const getClouds = async () => {

        let serverItems = await cloudService.getClouds();

        if (serverItems.data) {
            const serversFiltered = serverItems.data.filter((item) => item.status !== 'pending');
            setClouds(serversFiltered)
        }

        return clouds
    }

    useEffect(() => {
        getClouds()
    }, [])


    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Servers</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / Servers</h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h6 className="d-flex justify-content-between">Servers<div className="cardTitleButtons"><button disabled onClick={() => window.location.href = '/products/cloud/deploy'} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button></div></h6>
                                {clouds && <CloudTable data={clouds} rowsPerPage={5} refreshFunction={getClouds} pagination={true} />}
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>

            </div >

        </div >
    )
}

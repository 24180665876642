import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";
import logo_dark from '../../images/logo_white.png';
import userService from '../../services/user.service';

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await userService.requestPasswordReset(email);

            setMessage(response.message);
            setTimeout(() => navigate('/login'), 5000);
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message || 'Error requesting password reset');
            } else {
                setError('Error requesting password reset');
            }
        }
    };

    return (
        <div>
            <Helmet>
                <title>Centnodes | Request Password Reset</title>
                <meta name="description" content="Request a password reset." />
            </Helmet>
            <div className="hero-short p-1">
                <div className="container mt-5 justify-content-center d-flex">
                    <img src={logo_dark} className="loginLogo img-fluid" alt="company logo dark" />
                </div>
            </div>
            <Container>
                <div className="row d-flex justify-content-center loginRow pb-4">
                    <div className="col-md-6">
                        <div className="loginBox bg-light border p-2 pb-3 text-center">
                            <h4 className="h4 color-primary mt-3">Request Password Reset</h4>
                            <p className="color-gray">Enter your email to receive a password reset link.</p>
                            {error && <Alert className="p-2" variant={'danger'}><span>Error: </span> {error}</Alert>}
                            {message && <Alert variant={'success'}><span>{message}</span></Alert>}

                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="p-4 text-start d-grid gap-2">
                                    <Form.Label className="mt-2">Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button type="submit" className="btn btn-centhost">Submit</Button>
                                </div>
                            </Form>
                            <p className="m-0"><a target="_blank" href="https://centnodes.com/help"><FontAwesomeIcon className="mt-5" icon={faQuestionCircle} /> Trouble resetting your password?</a></p>
                            <p className="m-0"><a href="/"><FontAwesomeIcon className="mt-5" icon={faArrowAltCircleLeft} /> Back to homepage</a></p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PasswordResetRequest;

import { useEffect, useState } from "react";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import './Webhosting.css';
import Navigation from '../../../components/Navigation/Navigation'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBox, faChartBar, faGlobe, faInfinity, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import hostingService from "../../../services/hosting.service";

export const Webhosting = () => {

    const [hosting, setHosting] = useState()
    const [packageDetails, setPackageDetails] = useState();


    const getHosting = async (id) => {

        let hostingDetails = await hostingService.getHostingPackage(id);
        setHosting(hostingDetails.data)

        let packageDetails = await hostingService.getHostingPackageDetails(id);
        setPackageDetails(packageDetails.data.result)
    }

    useEffect(() => {
        getHosting(window.location.href.split("/hosting").pop().replace('/', ''))

    }, [])


    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Hosting details</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a> / <a className="breadcrumb" href="/products/hosting">Hosting </a> / {hosting && hosting.id && hosting.domain}</h6>

                <div className="row mt-3 text-center  make-equal ">
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faMailBulk} /> <p>{packageDetails && packageDetails.account_info.email_accounts_used} / {packageDetails && packageDetails.account_info.email_accounts} </p><span className="fw-bold"></span>E-mail</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faGlobe} /> <p>{packageDetails && (packageDetails.account_info.addons_domains_used + 1)} / {packageDetails && packageDetails.account_info.addons_domains} </p><span className="fw-bold"></span>Domains</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faChartBar} /><p>{packageDetails && packageDetails.account_info.bandwidth_used}mb / <FontAwesomeIcon icon={faInfinity} /> </p><span className="fw-bold"></span>Bandwith</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faBox} />  <p>{packageDetails && packageDetails.account_info.package_name}</p><span className="fw-bold"></span>Package</h5>
                        </div>
                    </div>
                </div>

                <Row>

                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Overview</Card.Title>
                                <a href="/products/hosting"><FontAwesomeIcon icon={faArrowLeft} size={'xl'} /></a>

                                {packageDetails ?

                                    <div className="row">
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul className="list-unstyled">
                                                    <li className="list-item my-1"><span className="fw-bold">Label: </span>{hosting.label ? hosting.label.replace('centnodes-', '') : 'N/a'}</li>
                                                    <li className="list-item my-1"><span className="fw-bold">Domain: </span>{hosting.domain ? hosting.domain : 'N/a'}</li>
                                                    <li className="list-item my-1"><span className="fw-bold">Username: </span>{hosting.username ? hosting.username : 'N/a'}</li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul className="list-unstyled">
                                                    <li className="list-item my-1"><span className="fw-bold">IP: </span>45.32.236.68</li>
                                                    <li className="list-item my-1"><span className="fw-bold">Nameserver 1: </span>ns1-ams-01.centnodes<span className="text-danger">.net</span></li>
                                                    <li className="list-item my-1"><span className="fw-bold">Nameserver 2: </span>ns2-ams-01.centnodes<span className="text-danger">.net</span></li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12  col-sm-6 col-md-4 col-lg-4">
                                            <div className="p-3">
                                                <ul class="list-group list-group-horizontal">
                                                    <li class="list-group-item btn btn-centhost btn-rounded"><a target="_blank" href={hosting ? 'https://' + hosting.server + '.centnodes.net:2083' : '#'}>Control Panel</a></li>
                                                    <li class="list-group-item btn btn-centhost btn-rounded"><a target="_blank" href={hosting ? 'https://' + hosting.server + '.centnodes.net:2087/pma/index.php?route=/' : '#'}>phpMyAdmin</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="text-center"><Spinner className="text-center" animation="border" variant="primary" /><h6 className="text-center">Loading...</h6></div>}
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Domains</Card.Title>
                                {packageDetails ?

                                    <div className="row">
                                        <div className="col-12">
                                            {packageDetails.domains.map((domain) => (<a target="_blank" className="d-block" href={'https://' + domain.domain}>{domain.domain}</a>))}
                                        </div>
                                    </div>
                                    : <div className="text-center"><Spinner className="text-center" animation="border" variant="primary" /></div>}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Sub Domains</Card.Title>
                                {packageDetails ? packageDetails.subdomins &&

                                    <div className="row">
                                        <div className="col-12">
                                            {packageDetails.subdomins.map((subdomin) => (<a target="_blank" href={'https://' + subdomin.subdomain + '.' + subdomin.domain}>{subdomin.subdomain + '.' + subdomin.domain}</a>))}
                                        </div>
                                    </div>
                                    : <div className="text-center"><Spinner className="text-center" animation="border" variant="primary" /></div>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import TwoFactor from "./pages/Login/TwoFactor";
import Dashboard from "./pages/Dashboard/Dashboard";
import Settings from "./pages/Settings/Settings";
import { Logout } from "./components/Logout/Logout";
import { Invoices } from "./pages/Invoices/Invoices";
import Checkout from "./pages/Checkout/Checkout";
import Error404 from "./pages/404/404";
import ScrolltoTop from "./hooks/scrolltoTop";
import Tickets from "./pages/Tickets/Tickets";
import Ticket from "./pages/Tickets/Ticket";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Webhostings } from "./pages/Products/Webhosting/Webhostings";
import { Webhosting } from "./pages/Products/Webhosting/Webhosting";
import { HelmetProvider } from "react-helmet-async";
import { GameServer } from "./pages/Products/Gaming/GameServer";
import { GameServers } from "./pages/Products/Gaming/GameServers";
import { DeployGameServer } from "./pages/Products/Gaming/DeployGameServer";
import Register from "./pages/Register/Register";
import ProtectedRoute from "./ProtectedRoute";
import { ToastContainer } from 'react-toastify';
import CreateTicket from "./pages/Tickets/CreateTicket";
import PasswordReset from "./pages/Login/PasswordReset";
import PasswordResetRequest from "./pages/Login/PasswordResetRequest";
import DeployCloud from "./pages/Products/Cloud/DeployCloud";
import { Clouds } from "./pages/Products/Cloud/Clouds";
import { Cloud } from "./pages/Products/Cloud/Cloud";
import { DeployWebhosting } from "./pages/Products/Webhosting/DeployWebhosting";

function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    try {
      let element = document.getElementById('backToTopButton');
      if (element) {
        window.addEventListener('scroll', function () {

          if (window.scrollY > 100) {
            element.classList.remove('d-none')
          }
          if (window.scrollY < 100) {
            element.classList.add('d-none')

          }
        });
      }
    } catch (err) {
      return
    }

  })

  return (
    <>
      <ToastContainer />
      <HelmetProvider>
        <ScrolltoTop />
        <Routes>

          <Route path="/*" element={<Error404 />} />
          <Route path="/" element={<Login />} />

          <Route path="/login" element={<Login />} />
          <Route path="/login/2fa" element={<TwoFactor />} />
          <Route path="/password/reset/:token" element={<PasswordReset />} />
          <Route path="/password/reset-request/" element={<PasswordResetRequest />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />


          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

          <Route path="/settings" element={<ProtectedRoute ><Settings /></ProtectedRoute>} />

          <Route path="/products/clouds" element={<ProtectedRoute ><Clouds /></ProtectedRoute>} />
          <Route path="/products/cloud/:id" element={<ProtectedRoute ><Cloud /></ProtectedRoute>} />
          <Route path="/products/cloud/deploy" element={<ProtectedRoute ><DeployCloud /></ProtectedRoute>} />

          <Route path="/products/hosting" element={<ProtectedRoute ><Webhostings /></ProtectedRoute>} />
          <Route path="/products/hosting/:id" element={<ProtectedRoute ><Webhosting /></ProtectedRoute>} />
          <Route path="/products/hosting/deploy" element={<ProtectedRoute ><DeployWebhosting /></ProtectedRoute>} />

          <Route path="/products/gaming" element={<ProtectedRoute ><GameServers /></ProtectedRoute>} />
          <Route path="/products/gaming/:id" element={<ProtectedRoute ><GameServer /></ProtectedRoute>} />
          <Route path="/products/gaming/deploy" element={<ProtectedRoute ><DeployGameServer /></ProtectedRoute>} />

          <Route path="/tickets" element={<ProtectedRoute ><Tickets /></ProtectedRoute>} />
          <Route path="/tickets/:ticket" element={<ProtectedRoute ><Ticket /></ProtectedRoute>} />
          <Route path="/ticket/new" element={<ProtectedRoute ><CreateTicket /></ProtectedRoute>} />

          <Route path="/invoices" element={<ProtectedRoute ><Invoices /></ProtectedRoute>} />

          <Route path="/checkout" element={<ProtectedRoute ><Checkout /></ProtectedRoute>} />

        </Routes>
      </HelmetProvider>
    </>
  );
}
export default App;

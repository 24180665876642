import {
  Navigate,
  Outlet,
} from 'react-router-dom';

const getToken = () => {

  const token = localStorage.getItem('token');

  return token;
};

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}) => {
  if (!getToken()) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute
import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./TicketTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const TicketTable = ({ data, rowsPerPage, refreshFunction }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);



    function changeDate(dbDate) {
        let date = new Date(dbDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes()

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (hour < 10) {
            hour = '0' + hour;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return year + '-' + month + '-' + dt + ' ' + hour + ":" + minutes;
    }
    const ticketStatus = (status) => {
        let bgColor;

        switch (status) {
            case "open":
                bgColor = "success"
                break;

            case "new":
                bgColor = "info"
                break;


            case "closed":
                bgColor = "danger"
                break;

            default:
                bgColor = "danger"

        }

        return bgColor;
    }
    const ticketPrio = (prio) => {
        let bgColor;

        switch (prio) {
            case "low":
                bgColor = "success"
                break;

            case "medium":
                bgColor = "warning"
                break;

            case "high":
                bgColor = "danger"
                break;

            default:
                bgColor = "danger"

        }

        return bgColor;
    }

    return (
        <div className="table-responsive table-dark">
            <table className="table">
                <thead>
                    <tr>
                        <th>Ticket</th>
                        <th>Subject</th>
                        <th>Department</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Last updated</th>
                    </tr>
                </thead>
                <tbody>
                    {slice.map((el) => (
                        <tr key={el.id}>
                            <td>{el.id}</td>
                            <td>{el.subject}</td>
                            <td>{el.department}</td>
                            <td> <p>{el.priority[0].toUpperCase(0) + el.priority.slice(1)}</p></td>
                            <td> <Badge className="p-2" bg={ticketStatus(el.status)}>{el.status[0].toUpperCase(0) + el.status.slice(1)}</Badge></td>
                            <td>{changeDate(el.updated_at)}</td>
                            <td><a href={"/tickets/" + el.id} className="btn btn-outline-centhost btn-sm">Details</a></td>
                        </tr>
                    ))}

                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </div>

    );
};

export default TicketTable;
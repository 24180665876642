import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowsRotate, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import './Cloud.css';
import Navigation from '../../../components/Navigation/Navigation';
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal.js";
import FirewallTable from "../../../components/Table/FirewallTable";
import serverService from "../../../services/server.service";
import OSimage from "../../../components/OSimage/OSimage";
import RegionFlag from "../../../components/RegionFlag/RegionFlag";
// import CloudOverview from "./components/Overview/CloudOverview";
// import CloudBackups from "./components/CloudBackup/CloudBackups";
// import CloudFirewall from "./components/CloudFirewall/CloudFirewall";
import { Spinner } from "react-bootstrap";
import CloudOverview from "./components/Overview.js";
import cloudService from "../../../services/cloud.service.js";

export const Cloud = () => {
    const [cloud, setCloud] = useState();
    const [backupBlock, setBackupBlock] = useState(true);
    const [cloudDetailsBlock, setCloudDetailsBlock] = useState(false);
    const [fireWallBlock, setFirewallBlock] = useState(true);
    const [recoveryBlock, setRecoveryBlock] = useState(true);
    const [destroyBlock, setDestroyBlock] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [actionType, setActionType] = useState();
    const [backupData, setBackupData] = useState();
    const [firewallIPv6Rules, setFirewallIPv6Rules] = useState();
    const [firewallIPv4Rules, setFirewallIPv4Rules] = useState();

    const getFirewallRules = async (cloudId, firewallGroupId) => {
        const firewall = await serverService.getFirewallRules(cloudId, firewallGroupId);

        const IPv4Rules = firewall.data.firewall_rules.filter(e => e.ip_type === 'v4');
        const IPv6Rules = firewall.data.firewall_rules.filter(e => e.ip_type === 'v6');

        setFirewallIPv4Rules(IPv4Rules);
        setFirewallIPv6Rules(IPv6Rules);
    }

    const getCloud = async (instanceId) => {
        const cloud = await cloudService.getCloud(instanceId);
        setCloud(cloud.data);

        // if (cloud.data.feature_backups == 1) {
        //     const backupData = await serverService.getInstanceBackupData(cloud.data.id);
        //     setBackupData(backupData.data);
        // }

        // if (cloud.data.firewall_group_id !== "") {
        //     getFirewallRules(cloud.data.id, cloud.data.firewall_group_id);
        // }

        return cloud;
    }

    const menuChange = (item) => {
        setBackupBlock(true);
        setCloudDetailsBlock(true);
        setFirewallBlock(true);
        setRecoveryBlock(true);
        setDestroyBlock(true);

        switch (item) {
            case 'backups':
                setBackupBlock(false);
                break;
            case 'firewall':
                setFirewallBlock(false);
                break;
            case 'recovery':
                setRecoveryBlock(false);
                break;
            case 'destroy':
                setDestroyBlock(false);
                break;
            case 'default':
                setCloudDetailsBlock(false);
                break;
        }
    }

    const hideConfirmationBox = () => {
        setShowConfirmation(false);
        setActionType(null);
    }

    const instanceAction = async (action, confirmed) => {
        if (confirmed === false) {
            setShowConfirmation(true);
            setActionType(action);
            return;
        } else {

            try {
                switch (action) {
                    case 'start':
                        await cloudService.startCloud(cloud.id);
                        break;
                    case 'stop':
                        await cloudService.stopCloud(cloud.id);
                        break;
                    case 'reboot':
                        await cloudService.rebootCloud(cloud.id);
                        break;
                    default:
                        break;
                }
                hideConfirmationBox();
                setTimeout(() => getCloud(cloud.id), 500);
            } catch (error) {
                console.error(`Error performing action ${action}:`, error);
            }
        }
    }

    useEffect(() => {
        getCloud(window.location.href.split("/cloud").pop().replace('/', ''));
    }, []);

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Server Details</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/clouds"> Servers</a> /
                    {cloud && cloud.label.replace('centnodes-', '')}
                </h6>
                <div className="row mt-3 gy-3">
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <OSimage osId={cloud ? cloud.os_id : '1'} height={'75px'} />
                                </div>
                                <div className="d-none d-sm-block col-6">
                                    <p>{cloud && cloud.os}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <RegionFlag regionId={cloud ? cloud.region : 'ams'} height={'55px'} />
                                </div>
                                <div className="d-none d-sm-block col-6">
                                    <p>{cloud && cloud.region}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="list-unstyled">
                                        <li className="list-item my-1">
                                            <p>Status:</p>
                                            {cloud && cloud.status === 'stopped' && <p className="text-danger"> Stopped</p>}
                                            {cloud && cloud.status === 'cancelled' && <p className="text-danger"> Cancelled</p>}
                                            {cloud && cloud.status === 'pending' && <p className="text-warning"> Pending</p>}
                                            {cloud && cloud.status === 'installing' && <p className="text-info"> Installing</p>}
                                            {cloud && cloud.status === 'running' && <p className="text-success"> Running</p>}
                                        </li>
                                        <li className="list-item my-1"><p>IP: {cloud && cloud.ip}</p></li>
                                        <li className="list-item my-1 d-none d-sm-block"><p>Hostname: {cloud && cloud.hostname}</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="p-3 serverStatBox">
                            <div className="d-grid gap-2 d-flex justify-content-center">
                                <button onClick={() => instanceAction('start', false)} className="bootOption text-white"><FontAwesomeIcon icon={faPlay} /></button>
                                <button onClick={() => instanceAction('reboot', false)} className="bootOption text-white"><FontAwesomeIcon icon={faArrowsRotate} /></button>
                                <button onClick={() => instanceAction('stop', false)} className="bootOption text-white"><FontAwesomeIcon icon={faStop} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 mb-0">
                    <div className="col-12 col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title">Cloud</div>
                                <ul className="serversMenu p-0">
                                    <li className="list-item"><a onClick={() => menuChange('default')} href="javascript:void(0)" className="nav-link">Overview</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('backups')} href="javascript:void(0)" className="nav-link">Backups</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('firewall')} href="javascript:void(0)" className="nav-link">Firewall</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('networking')} href="javascript:void(0)" className="nav-link">Networking</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('snapshots')} href="javascript:void(0)" className="nav-link">Snapshots</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('upgrade')} href="javascript:void(0)" className="nav-link">Upgrade</a></li>
                                    <li className="list-item my-2 p-1"><a onClick={() => menuChange('ssh')} href="javascript:void(0)" className="nav-link">SSH Keys</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="card" hidden={cloudDetailsBlock}>
                            {cloud ? <CloudOverview cloudDetails={cloud} /> : <div className="text-center p-3"><Spinner className="text-center" animation="border" variant="primary" /><h6 className="text-center">Getting cloud details</h6></div>}
                        </div>

                        <div className="card" hidden={backupBlock}>
                            {/* {backupData && backupData.backups.length > 0 ? <CloudBackups backupData={backupData} /> : (
                                <div className="row d-flex justify-content-center p-3">
                                    <div className="col-md-12 mt-3">
                                        <h3 className="h3">You need to enable the backup feature.</h3>
                                        <p>If you want to add backups, please create a ticket.</p>
                                        <a href="/tickets" className="btn btn-centhost mt-2">Create ticket</a>
                                    </div>
                                </div>
                            )} */}
                        </div>

                        <div className="card" hidden={fireWallBlock}>
                            {/* {cloud && cloud.firewall_group_id ? <CloudFirewall refreshFunction={getFirewallRules} firewallv4Rules={firewallIPv4Rules} firewallv6Rules={firewallIPv6Rules} firewallGroupId={cloud.firewall_group_id} /> : (
                                <div className="row d-flex justify-content-center p-3">
                                    <div className="col-md-12 mt-3">
                                        <h3 className="h3">You need to add a firewall group to this cloud first.</h3>
                                        <button className="btn btn-centhost mt-2">Create</button>
                                    </div>
                                </div>
                            )} */}
                        </div>

                        <div className="card" hidden={recoveryBlock}>
                            <div className="card-body">
                                <div className="card-title">Rebuild</div>
                                <a href="/products/clouds"><FontAwesomeIcon icon={faArrowLeft} size={'xl'} /></a>
                                {cloud ? (
                                    <div>
                                        <p>Rebuild</p>
                                    </div>
                                ) : (
                                    <div className="text-center"><Spinner className="text-center" animation="border" variant="primary" /><h6 className="text-center">Getting cloud details</h6></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal isOpen={showConfirmation} type="instanceAction" onHide={hideConfirmationBox} onConfirm={() => instanceAction(actionType.toString(), true)}></ConfirmationModal>
        </div>
    );
}

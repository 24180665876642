import { useEffect } from "react";
import './RegionFlag.css';
import amsFlag from '../../images/flags/flag_nl.png'

const RegionFlag = (props) => {

    return (
        <>
            <div className="d-flex justify-content-start">
                <img className="mt-2" height={props.height ? props.height : '100%'} src={amsFlag} />
            </div>
        </>
    )
}
export default RegionFlag;
import { useEffect } from 'react'

export const Logout = () => {
  function delete_cookie(name, path, domain) {

      document.cookie = name + "=" +
        ((path) ? ";path=" + path : "") +
        ((domain) ? ";domain=" + domain : "") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    
  }


  const logOut = () => {
    localStorage.clear()
    delete_cookie("CENTNODES-AUTH-SECRET", '/', window.location.href.includes("localhost") ? 'localhost' : '.centnodes.com')
    window.location.assign("/login");
  }

  useEffect(() => {
    logOut()
  }, [])

  return (
    <div>Logging out..</div>
  )
}

import React, { useEffect } from "react";
import { Next } from "react-bootstrap/esm/PageItem";

import styles from "./TableFooter.css";

const TableFooter = ({ range, setPage, page, slice }) => {

  const nextPage = () => {
    if(!page !== 14){
      setPage(page++)
    }
  }
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);


  return (
    <div className={styles.tableFooter}>
            {/* <button onClick={setPage(page - 1)}>&larr;</button> */}

      {range.map((el, index) => (
        <button
          key={index}
          className={`${styles.button} ${page === el ? styles.activeButton : styles.inactiveButton
            }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))}
      {/* <button onClick={nextPage}>&rarr;</button> */}
    </div>
  );
};

export default TableFooter;
import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./CloudTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import RegionFlag from "../RegionFlag/RegionFlag";
import OSimage from "../OSimage/OSimage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const CloudTable = ({ data, rowsPerPage, refreshFunction, pagination = true, disableHeader = false }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);


    return (
        <div className="serverTable">
            {disableHeader != true &&
                <div className="row justify-content-start d-flex">
                    <div className="col-6 col-sm-3">
                        <p className="fw-bold">Label</p>
                    </div>
                    <div className="col-2 d-none d-sm-flex">
                        <p className="fw-bold">Region</p>
                    </div>
                    <div className="col-2 d-none d-sm-flex">
                        <p className="fw-bold">OS</p>
                    </div>
                    <div className="col-4 col-sm-3">
                        <p className="fw-bold">Status</p>
                    </div>
                </div>
            }
            {slice.map((server) => (
                <div onClick={() => window.location.assign(server.status === 'installing' || server.status === 'pending' || server.status === 'cancelled' ? '# ' : '/products/cloud/' + server.id)} key={server.id} className="row serverRow justify-content-start d-flex my-2">
                    <div className="col-4">
                        <p className="m-0 p-0">{server.label}</p>
                        <small className="m-0 p-0 d-none d-sm-flex">{server.ip}</small>
                    </div>
                    <div className="col-2 d-none d-sm-block">
                        <RegionFlag height={'20px'} regionId={server.region} />
                    </div>
                    <div className="col-2 d-none d-sm-block">
                        <OSimage osId={server.os_id} height={'40px'} />
                    </div>
                    <div className="col-3">
                        {server.status === 'running' && <Badge bg="success">running</Badge>}
                        {server.status === 'pending' && <Badge bg="warning">pending</Badge>}
                        {server.status === 'cancelled' && <Badge bg="secondary">cancelled</Badge>}
                        {server.status === 'stopped' && <Badge bg="danger">stopped</Badge>}
                        {server.status === 'installing' && <Badge bg="info">installing</Badge>}
                    </div>
                    <div className="col-1 col-sm-2 justify-content-end d-flex">
                        <p className="mt-3"><FontAwesomeIcon icon={faArrowRight} /></p>
                    </div>
                </div>
            ))}

            {pagination && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}

        </div>

    );
};

export default CloudTable;
import axios from "axios";
import authService from "./auth.service";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);
const token = localStorage.getItem('token')
const config = { headers: { 'Authorization': 'Bearer ' + token } }

const getServers = async () => {
    try {
        const servers = await axios.get(API_URL + '/servers', config)

        return servers.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getServer = async (id) => {
    try {
        const servers = await axios.get(API_URL + '/servers/' + id, config)

        return servers.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }

    }
}

const getServerDetails = async (id) => {
    try {
        const servers = await axios.get(API_URL + '/servers/' + id + '/details', config)

        return servers.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getInstanceBackupData = async (id) => {
    try {
        const backupData = await axios.get(API_URL + '/servers/' + id + '/backups', config)

        return backupData.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const startInstance = async (id) => {
    try {
        const start = await axios.post(API_URL + '/servers/' + id + '/start', {}, config)

        return start.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const rebootInstance = async (id) => {
    try {
        const reboot = await axios.post(API_URL + '/servers/' + id + '/reboot', {}, config)

        return reboot.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const stopInstance = async (id) => {
    try {
        const stop = await axios.post(API_URL + '/servers/' + id + '/stop', {}, config)

        return stop.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getOperatingSystems = async () => {
    try {
        const osList = await axios.get(API_URL + '/oslist');

        return osList.data.data.os
    } catch (err) {
        console.log(err)
    }
}
const getApplications = async () => {
    try {
        const appList = await axios.get(API_URL + '/applist');

        return appList.data.data.applications
    } catch (err) {
        console.log(err)
    }
}

const updateBackupSchedule = async (serverId, schedule) => {
    try {
        const updateSchedule = await axios.post(API_URL + '/servers/' + serverId + '/backup-schedule', { id: serverId, schedule }, config)

        return updateSchedule.data.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getFirewallRules = async (serverId, firewallGroupId) => {
    try {
        const firewallRules = await axios.get(API_URL + '/servers/' + serverId + '/firewall/' + firewallGroupId, config)

        return firewallRules.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const addFirewallRule = async (serverId, firewallGroupId, rule) => {
    try {
        const addRule = await axios.post(API_URL + '/servers/' + serverId + '/firewall/' + firewallGroupId, { rule }, config)

        return addRule.data.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const removeFirewallRule = async (serverId, firewallGroupId, ruleId) => {
    try {
        const removeRule = await axios.delete(API_URL + '/servers/' + serverId + '/firewall/' + firewallGroupId + '/' + ruleId, config)

        return removeRule.data.status
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

export default { removeFirewallRule, addFirewallRule, getFirewallRules, updateBackupSchedule, getServers, getInstanceBackupData, getServer, getServerDetails, startInstance, rebootInstance, stopInstance, getOperatingSystems, getApplications }
import { useEffect, useState } from "react"
import { Card, Badge } from "react-bootstrap";
import './Dashboard.css';
import Navigation from '../../components/Navigation/Navigation'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faFileInvoice, faGamepad, faMicrochip, faPlus, faServer } from "@fortawesome/free-solid-svg-icons";
import serverService from "../../services/server.service";
import HostingTable from "../../components/Table/HostingTable";
import hostingService from "../../services/hosting.service";
import gamingService from "../../services/gaming.service";
import GamingTable from "../../components/Table/GamingTable";
import invoiceService from "../../services/invoice.service";
import ticketService from "../../services/ticket.service";
import CloudTable from "../../components/Table/CloudTable";
import cloudService from "../../services/cloud.service";

function Dashboard(props) {
    const [hostingPackages, setHostingPackages] = useState(null)
    const [gamingPackages, setGamingPackages] = useState(null)
    const [servers, setServers] = useState(null)
    const [tickets, setTickets] = useState(null)
    const [invoices, setInvoices] = useState(null)

    const navigate = (route) => {
        window.location.href = route;
    }

    const getInvoices = async () => {
        let inv = await invoiceService.getInvoices();
        let openInvoices = inv.data.filter((inv) => inv.status === 'payment_due' || inv.status === 'unpaid');

        setInvoices(openInvoices)
    }

    const getTickets = async () => {
        let ticketData = await ticketService.getTickets();

        setTickets(ticketData.data)
    }

    async function getHostingPackages() {
        let hostingPackagesData = await hostingService.getHostingPackages();
        if (hostingPackagesData) {
            let hostingPackagesFiltered = hostingPackagesData.data.filter(hostpack => hostpack.status !== 'pending');
            setHostingPackages(hostingPackagesFiltered);
        }
    }

    const getGamingPackages = async () => {
        let gamingPackagesData = await gamingService.getGamingPackages();
        if (gamingPackagesData) {
            let gamingPackagesFiltered = gamingPackagesData.data.filter(gamepack => gamepack.status !== 'pending')
            setGamingPackages(gamingPackagesFiltered)
        }
    }

    const getServers = async () => {
        let servers = await cloudService.getClouds()
        if (servers) {
            let serversFiltered = servers.data.filter((item) => item.server_status !== 'pending');
            setServers(serversFiltered)
        }
    }

    useEffect(() => {
        getServers()
        getHostingPackages()
        getGamingPackages()
        getTickets()
        getInvoices()
    }, [])

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Dashboard</h4>
                <h6 className="mt-1 breadcrumb"><a className="breadcrumb" href="/dashboard">Dashboard</a></h6>
                <div className="row mt-3 text-center  make-equal ">
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5 className="mt-1"><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faMicrochip} /> <span className="fw-bold">{servers ? servers.length : 0}</span> Servers</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5 className="mt-1"><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faGamepad} /> <span className="fw-bold">{gamingPackages ? gamingPackages.length : 0}</span> Gaming</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 ">
                        <div className="card p-3 h-75">
                            <h5 className="mt-1"><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faServer} /> <span className="fw-bold">{hostingPackages ? hostingPackages.length : 0}</span> Hosting</h5>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="card p-3 h-75">
                            <h5 className="mt-1"><FontAwesomeIcon className="d-block d-md-inline-block text-center" color={'#2f57a4'} icon={faFileInvoice} /> <span className="fw-bold">{invoices ? invoices.length : 0}</span> Invoices</h5>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Servers<div className="cardTitleButtons"><button disabled onClick={() => navigate('/products/cloud/deploy')} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button><button onClick={() => navigate('/products/clouds/')} className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>
                                {servers && <CloudTable data={servers} rowsPerPage={3} refreshFunction={getServers} pagination={false} disableHeader={true} />}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Web Hosting<div className="cardTitleButtons"><button onClick={() => navigate('/products/hosting/deploy')} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button><button onClick={() => navigate('/products/hosting/')} className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>
                                {hostingPackages && <HostingTable data={hostingPackages} rowsPerPage={3} refreshFunction={getHostingPackages} pagination={false} disableHeader={true} />}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Game Hosting<div className="cardTitleButtons"><button onClick={() => navigate('/products/gaming/deploy')} className="btn-sm btn-centhost"><FontAwesomeIcon icon={faPlus} /> Deploy</button><button onClick={() => navigate('/products/gaming/')} className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>
                                {gamingPackages && <GamingTable data={gamingPackages} rowsPerPage={3} refreshFunction={getGamingPackages} pagination={false} disableHeader={true} />}

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Invoices <div className="cardTitleButtons"><button onClick={() => navigate('/invoices')} className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>
                                {invoices && invoices.length > 0 ? invoices.filter((invoice) => invoice.status === 'unpaid' || invoice.status === 'payment_due').map((el) => (
                                    <div key={el.id} className="serverRow col-md-12">

                                        <div className="row p-2  ">
                                            <a href='/invoices/' className={'mt-5'} id={el.id}>
                                                <div className="col-5 contentsPadding">
                                                    <span className=""># {el.id}</span><br />

                                                </div>
                                                <div className="col-5 contentsPadding">
                                                    <span className="">&euro;{el.payment_amount}</span><br />

                                                </div>
                                                <div className="col-2 d-flex justify-content-end">
                                                    <span className="mx-2"><FontAwesomeIcon color={"gray"} icon={faArrowRight} /> </span>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                ))
                                    : <p className="text-center">No open invoices.</p>}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Tickets<div className="cardTitleButtons"><button onClick={() => window.location.assign('/ticket/new')} className="btn-sm btn-centhost d-none d-lg-block"><FontAwesomeIcon icon={faPlus} /> New</button><button onClick={() => navigate('/tickets')} className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>
                                {tickets && tickets.length > 0 ? tickets.filter((item, idx) => idx < 3).map((el) => (

                                    <div key={el.id} className="serverRow col-md-12">
                                        <div className="row p-2  ">
                                            <a className={'mt-5'} href={'/tickets/' + el.id} id={el.id}>
                                                <div className="col-6 contentsPadding col-md-4 col-lg-4">
                                                    <span className="fw-bold">{el.subject}</span><br />
                                                    {/* <span>{el.department}</span> */}

                                                </div>
                                                <div className="col-4 d-flex justify-content-center">
                                                    <span>
                                                        {el.status === 'new' && <td className=""><Badge className="d-inline" bg="info">New</Badge></td>}
                                                        {el.status === 'open' && <td className=""><Badge className="d-inline" bg="info">Open</Badge></td>}
                                                        {el.priority === 'low' && <td className=""><Badge className="d-inline" bg="info">Low</Badge></td>}
                                                    </span>
                                                </div>
                                                <div className="col-2 d-flex justify-content-end">
                                                    <span><FontAwesomeIcon color={"gray"} icon={faArrowRight} /> </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                )) :
                                    <div className="text-center mt-5  mx-auto"><p>No open tickets.</p></div>
                                }
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-body">
                                <Card.Title className="d-flex justify-content-between">Activity Logs<div className="cardTitleButtons"><button className="btn-outline-centhost btn-sm">View all</button></div></Card.Title>

                                <p className="text-center">No logs.</p>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Dashboard;
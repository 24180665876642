import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./InvoiceTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const InvoiceTable = ({ data, rowsPerPage, refreshFunction, pagination = true }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);


    return (
        <div className="serverTable">
            <div className="row justify-content-start d-flex">
                <div className="col-3 col-sm-3">
                    <p className="fw-bold">Invoice</p>
                </div>
                <div className="col-2 d-none d-sm-flex">
                    <p className="fw-bold">Product</p>
                </div>
                <div className="col-3 col-sm-2">
                    <p className="fw-bold">Amount</p>
                </div>
                <div className="col-3 col-sm-2">
                    <p className="fw-bold">Status</p>
                </div>
            </div>
            {slice.map((invoice) => (
                <div onClick={() => window.open(invoice.status === 'cancelled' ? '# ' : invoice.payment_url, '_blank')} key={invoice.id} className="row serverRow justify-content-start d-flex my-2">
                    <div className="col-3 col-sm-3">
                        <p className="m-0 p-0"># {invoice.id}</p>
                        <small className="m-0 p-0 d-sm-none d-sm-flex">{invoice.orderDetails.vendor_product_id}</small>
                    </div>
                    <div className="col-2 d-none d-sm-block">
                        <p>{invoice.orderDetails.vendor_product_id}</p>
                    </div>
                    <div className="col-3 col-sm-2">
                        <p>&euro; {invoice.payment_amount}</p>
                    </div>
                    <div className="col-3 col-sm-2">
                        {invoice.status === 'paid' && <Badge bg="success">Paid</Badge>}
                        {invoice.status === 'open' && <Badge bg="warning">Unpaid</Badge>}
                        {invoice.status === 'payment_due' && <Badge bg="danger">Payment Due</Badge>}
                        {invoice.status === 'expired' && <Badge bg="secondary">Cancelled</Badge>}
                    </div>
                    <div className="col-2 justify-content-end d-flex">
                        <p className="mt-3"><FontAwesomeIcon icon={faArrowRight} /></p>
                    </div>
                </div>
            ))}

            {pagination && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}

        </div>

    );
};

export default InvoiceTable;
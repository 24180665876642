import axios from "axios";
import authService from "./auth.service";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);
const token = localStorage.getItem('token')
const config = { headers: { 'Authorization': 'Bearer ' + token } }

const createTicket = async (data) => {
    try {
        const createTicket = await axios.post(API_URL + '/ticket', data, config)

        return createTicket.data
    } catch (err) {

        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getTickets = async () => {
    try {
        const tickets = await axios.get(API_URL + '/ticket', config)

        return tickets.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}

const getTicket = async (id) => {
    try {
        const ticket = await axios.get(API_URL + '/ticket/' + id, config)

        return ticket.data
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }

    }
}

const postTicketComment = async (data) => {
    try {
        const postComment = await axios.post(API_URL + '/ticket/comment/add', data, config)

        return postComment.data
    } catch (err) {

        if (err.response.status === 403) {
            return authService.logout();
        }
    }
}


export default { createTicket, getTickets, getTicket, postTicketComment } 